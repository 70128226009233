import { graphql } from 'gatsby'
import get from 'lodash/get'
import React from 'react'
import Helmet from 'react-helmet'
import EmailPopup from '../components/EmailPopup'
import Footer from '../components/footer'
import Layout from '../components/layout'
import RecipeFeature from '../components/recipe-feature'
import RecipePreview from '../components/recipe-preview'
import styles from './featured-recipes.module.css'

class FeaturedRecipes extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const posts = get(this, 'props.data.newest.edges')
    const chinese = get(this, 'props.data.chinese.edges')
    const featured = get(this, 'props.data.contentfulRecipe')
    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#fff' }}>
          <Helmet title={siteTitle}>
            <meta property="og:title" content="Journey East" />
            <meta property="og:type" content="website" />
            <meta
              property="og:description"
              content="Creative recipes from a Cantonese-American home cook in Tokyo."
            />
            <meta
              property="og:image"
              content="https://journeyeast.co/static/bc74c1ea2de3a99286bd1cb5f6928bd1/88d05/illo1.png"
            />
            <meta property="og:url" content="https://journeyeast.co/" />
            <EmailPopup />
          </Helmet>
          <div className={styles.recipeArea}>
            <RecipeFeature recipe={featured} />
          </div>
          <div className={styles.section}>
            <h2 className={styles.label}>Fresh & New</h2>
            <ul className="article-list" className={styles.blueRecipeArea}>
              {posts.map(({ node }) => (
                <RecipePreview
                  key={node.slug}
                  recipe={node}
                  className={styles.indexRecipe}
                  isBlueBackground
                />
              ))}
            </ul>
          </div>
        </div>
        <div>
          <h2 className={styles.label}>Vaguely Chinese</h2>
          <ul className="article-list" className={styles.recipeArea}>
            {chinese.map(({ node }) => {
              return (
                <RecipePreview
                  key={node.slug}
                  recipe={node}
                  className={styles.indexRecipe}
                />
              )
            })}
          </ul>
        </div>
        <Footer />
      </Layout>
    )
  }
}

export default FeaturedRecipes

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    contentfulRecipe(slug: { eq: "peach-and-pistachio-tart" }) {
      title
      slug
      hanzi
      photo {
        fluid(maxWidth: 800) {
          ...GatsbyContentfulFluid
        }
      }
      tagline {
        tagline
      }
    }
    chinese: allContentfulRecipe(
      sort: { fields: [publishDate], order: DESC }
      limit: 6
      filter: { cuisine: { in: "Chinese" } }
    ) {
      edges {
        node {
          title
          slug
          hanzi
          tagline {
            tagline
          }
          photo {
            fluid(maxWidth: 600) {
              ...GatsbyContentfulFluid
            }
          }
          publishDate(formatString: "MMMM Do, YYYY")
        }
      }
    }
    newest: allContentfulRecipe(
      sort: { fields: [publishDate], order: DESC }
      filter: { cuisine: { nin: "Chinese" } }
      limit: 6
    ) {
      edges {
        node {
          title
          slug
          hanzi
          tagline {
            tagline
          }
          photo {
            fluid(maxWidth: 600) {
              ...GatsbyContentfulFluid
            }
          }
          publishDate(formatString: "MMMM Do, YYYY")
        }
      }
    }
  }
`
