import React, { useEffect } from 'react'

export default function EmailPopup(props) {
  useEffect(() => {
    window.dojoRequire(['mojo/signup-forms/Loader'], function(L) {
      L.start({
        baseUrl: 'mc.us4.list-manage.com',
        uuid: '9786728ce5b7b2c3b7e0fae52',
        lid: 'aaa8fa0b51',
        uniqueMethods: true,
      })
    })
  }, [])

  return (
    <React.Fragment>
      <script
        type="text/javascript"
        src="//downloads.mailchimp.com/js/signup-forms/popup/unique-methods/embed.js"
        data-dojo-config="usePlainJson: true, isDebug: false"
      ></script>
    </React.Fragment>
  )
}
