import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import RichText from './rich-text'
import styles from './recipe-feature.module.css'

export default ({ recipe }) => (
  <Link className={styles.recipeLink} to={`/recipe/${recipe.slug}`}>
    <div className={styles.feature}>
      <Img className={styles.photo} alt="" fluid={recipe.photo.fluid} />
      <div className={styles.desc}>
        <div className={styles.label}>SUMMER SWEETS</div>
        <h2 className={styles.recipeTitle}>{recipe.title}</h2>
        <p className={styles.tagline}>{recipe.tagline.tagline}</p>
      </div>
    </div>
  </Link>
)
